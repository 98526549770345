import * as Sentry from "@sentry/vue";

export default (function initializeSentry() {
  let sentryInitialized = false;
  return (vueApp: any) => {
    if (sentryInitialized) return;

    Sentry.init({
      app: vueApp,
      environment: import.meta.env.VITE_NODE_ENV,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/go-checkout.bioniq\.com/],
      // Session Replay
      replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    sentryInitialized = true;
  };
})();
